a, a:visited,a:hover,a:active {
    color: blue;
    font-weight:bold;
    font-family:sans-serif;
}
a:hover {
    color: orange;
}
a:active {
    color: yellow;
}